// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Select from "../../../../../../styleguide/forms/Select/Select.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderLocationsIndexContactFormsScss from "./ProviderLocationsIndexContactForms.scss";
import * as ProviderLocationsIndexContactFormBareMetal from "./components/bare-metal/ProviderLocationsIndexContactFormBareMetal.res.js";
import * as ProviderLocationsIndexContactFormColocation from "./components/colocation/ProviderLocationsIndexContactFormColocation.res.js";

var css = ProviderLocationsIndexContactFormsScss;

var forms = [
  "Colocation",
  "BareMetal"
];

var initialState = {
  currentForm: "Colocation"
};

function formToString(form) {
  if (form === "Colocation") {
    return "Colocation";
  } else {
    return "Bare Metal";
  }
}

function formFromString(formType) {
  switch (formType) {
    case "Bare Metal" :
        return "BareMetal";
    case "Colocation" :
        return "Colocation";
    default:
      return "Colocation";
  }
}

function ProviderLocationsIndexContactForms(props) {
  var setSubmitted = props.setSubmitted;
  var provider = props.provider;
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    currentForm: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  if (provider.bareMetalDirectory) {
    var match$1 = state.currentForm;
    var tmp$1;
    tmp$1 = match$1 === "Colocation" ? JsxRuntime.jsx(ProviderLocationsIndexContactFormColocation.make, {
            provider: provider,
            setSubmitted: setSubmitted
          }) : JsxRuntime.jsx(ProviderLocationsIndexContactFormBareMetal.make, {
            provider: provider,
            setSubmitted: setSubmitted
          });
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs("div", {
                          children: [
                            "Select a Service",
                            JsxRuntime.jsx("span", {
                                  children: "*",
                                  className: css.required
                                })
                          ],
                          className: css.label
                        }),
                    JsxRuntime.jsxs(Select.make, {
                          id: "formType",
                          value: formToString(state.currentForm),
                          className: css.selectInput,
                          wrapperClassName: css.region,
                          iconSize: "XXS",
                          iconColor: "GrayText",
                          onChange: (function ($$event) {
                              dispatch({
                                    TAG: "ToggleForm",
                                    _0: formFromString($$event.target.value)
                                  });
                            }),
                          children: [
                            JsxRuntime.jsx(Select.DefaultOption.make, {
                                  children: "Region"
                                }),
                            Belt_Array.mapWithIndex(forms, (function (index, form) {
                                    var value = formToString(form);
                                    return JsxRuntime.jsx(Select.$$Option.make, {
                                                value: value,
                                                children: value
                                              }, String(index));
                                  }))
                          ]
                        })
                  ],
                  className: css.field
                }),
            tmp$1
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(ProviderLocationsIndexContactFormColocation.make, {
          provider: provider,
          setSubmitted: setSubmitted
        });
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: css.contactFormContainer
            });
}

var make = ProviderLocationsIndexContactForms;

export {
  css ,
  forms ,
  initialState ,
  formToString ,
  formFromString ,
  make ,
}
/* css Not a pure module */
