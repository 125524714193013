// extracted by mini-css-extract-plugin
export var breadcrumbContainer = "ProviderLocationsIndexHeader__breadcrumbContainer__iHFEw";
export var breadcrumbLink = "ProviderLocationsIndexHeader__breadcrumbLink__JSv9W";
export var column = "ProviderLocationsIndexHeader__column__Bgm8B";
export var downloadIcon = "ProviderLocationsIndexHeader__downloadIcon__xb8Zf";
export var dropdownBody = "ProviderLocationsIndexHeader__dropdownBody__CvfSg";
export var dropdownBodyRow = "ProviderLocationsIndexHeader__dropdownBodyRow__Eq1Xt";
export var dropdownTrigger = "ProviderLocationsIndexHeader__dropdownTrigger__yBVGI";
export var editIcon = "ProviderLocationsIndexHeader__editIcon__Cn05D";
export var filtersContainer = "ProviderLocationsIndexHeader__filtersContainer__FxQgn";
export var flex = "ProviderLocationsIndexHeader__flex__yNEAf";
export var flexColumn = "ProviderLocationsIndexHeader__flexColumn__hf18D";
export var gap1 = "ProviderLocationsIndexHeader__gap1__zZD0R";
export var gap2 = "ProviderLocationsIndexHeader__gap2__iB60b";
export var gap3 = "ProviderLocationsIndexHeader__gap3__xlSDp";
export var gap4 = "ProviderLocationsIndexHeader__gap4__jVFiZ";
export var gap5 = "ProviderLocationsIndexHeader__gap5__e0oeZ";
export var headerContainer = "ProviderLocationsIndexHeader__headerContainer__p6gVZ";
export var label = "ProviderLocationsIndexHeader__label__Fvisl";
export var locationsCount = "ProviderLocationsIndexHeader__locationsCount__V9Q5a";
export var providersDropdown = "ProviderLocationsIndexHeader__providersDropdown__PLfup";
export var providersSearch = "ProviderLocationsIndexHeader__providersSearch__m4bQC";
export var reset = "ProviderLocationsIndexHeader__reset__UfLdz";
export var row = "ProviderLocationsIndexHeader__row__LQlJG";
export var searchContainer = "ProviderLocationsIndexHeader__searchContainer__hOMK2";
export var searchField = "ProviderLocationsIndexHeader__searchField__OGFZs";
export var title = "ProviderLocationsIndexHeader__title__nNNKn";
export var titleContainer = "ProviderLocationsIndexHeader__titleContainer__gpPyu";