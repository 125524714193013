// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import Message_sentPng from "./images/message_sent.png";
import * as ProviderLocationsIndexContactForms from "./forms/ProviderLocationsIndexContactForms.res.js";
import * as ProviderLocationsIndexContactScss from "./ProviderLocationsIndexContact.scss";

var css = ProviderLocationsIndexContactScss;

var messageSent = Message_sentPng;

var initialState = {
  submitted: false
};

function getProfileImage(image, cloudName) {
  return "https://res.cloudinary.com/" + cloudName + "/image/upload/c_scale,w_600/c_crop,g_face,h_600,r_max,w_600/c_scale,w_405/" + image;
}

function ProviderLocationsIndexContact(props) {
  var provider = props.provider;
  var reducer = function (_state, action) {
    return {
            submitted: action._0
          };
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  if (match[0].submitted) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Picture.make, {
                              src: messageSent,
                              large: [
                                90,
                                70
                              ],
                              crop: "Fit",
                              imageClassName: css.envelopeImage
                            }),
                        className: css.contactEnvelope
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Your Message Has Been Sent!",
                        className: css.contactMessage
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Expect a response within 1 business day.",
                        className: css.contactMessageSub
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Button.make, {
                              size: "SM",
                              color: "Primary",
                              onClick: (function (param) {
                                  dispatch({
                                        TAG: "SetSubmitted",
                                        _0: false
                                      });
                                }),
                              children: "Start Over"
                            }),
                        className: css.resubmit
                      })
                ],
                className: css.contactSubmitted
              });
  } else {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Picture.make, {
                              src: provider.logo,
                              large: [
                                120,
                                80
                              ],
                              crop: "Fit"
                            }),
                        className: css.logo
                      }),
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("div", {
                                children: "Contact " + provider.name,
                                className: css.providerName
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  "Complete the form to ",
                                  JsxRuntime.jsx("strong", {
                                        children: "get in direct contact with " + provider.name + "."
                                      })
                                ],
                                className: css.contactSubtitle
                              })
                        ],
                        className: css.contactTitle
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(ProviderLocationsIndexContactForms.make, {
                              provider: provider,
                              setSubmitted: (function (value) {
                                  dispatch({
                                        TAG: "SetSubmitted",
                                        _0: value
                                      });
                                })
                            }),
                        className: css.contactForm
                      })
                ],
                className: css.providerContactContainer
              });
  }
}

var make = ProviderLocationsIndexContact;

export {
  css ,
  messageSent ,
  initialState ,
  getProfileImage ,
  make ,
}
/* css Not a pure module */
