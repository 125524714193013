// extracted by mini-css-extract-plugin
export var column = "ProviderLocationsIndexContactForms__column__WcYuC";
export var field = "ProviderLocationsIndexContactForms__field__Fk_v0";
export var fieldInput = "ProviderLocationsIndexContactForms__fieldInput__Pj1Fw";
export var flex = "ProviderLocationsIndexContactForms__flex__Kk1FJ";
export var flexColumn = "ProviderLocationsIndexContactForms__flexColumn___p7HQ";
export var gap1 = "ProviderLocationsIndexContactForms__gap1__AcpoX";
export var gap2 = "ProviderLocationsIndexContactForms__gap2__bnzpi";
export var gap3 = "ProviderLocationsIndexContactForms__gap3__eY_YV";
export var gap4 = "ProviderLocationsIndexContactForms__gap4__g_zhk";
export var gap5 = "ProviderLocationsIndexContactForms__gap5__hC6jD";
export var label = "ProviderLocationsIndexContactForms__label__BhjXw";
export var required = "ProviderLocationsIndexContactForms__required__f3cva";
export var row = "ProviderLocationsIndexContactForms__row__Gve7e";
export var selectInput = "ProviderLocationsIndexContactForms__selectInput__dqjZF";