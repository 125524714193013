// extracted by mini-css-extract-plugin
export var column = "ProviderLocationsIndexContact__column__uj4aU";
export var contactEnvelope = "ProviderLocationsIndexContact__contactEnvelope__R9zcJ";
export var contactJobTitle = "ProviderLocationsIndexContact__contactJobTitle__Wf_AV";
export var contactMessage = "ProviderLocationsIndexContact__contactMessage__Cmtse";
export var contactMessageSub = "ProviderLocationsIndexContact__contactMessageSub__ciVze";
export var contactName = "ProviderLocationsIndexContact__contactName__Z8e6s";
export var contactPerson = "ProviderLocationsIndexContact__contactPerson__Bw5nV";
export var contactPersonImage = "ProviderLocationsIndexContact__contactPersonImage__WdFjV";
export var contactSubmitted = "ProviderLocationsIndexContact__contactSubmitted__KNwMJ";
export var contactSubtitle = "ProviderLocationsIndexContact__contactSubtitle__FxjbG";
export var contactSummary = "ProviderLocationsIndexContact__contactSummary__eRMom";
export var contactTitle = "ProviderLocationsIndexContact__contactTitle__sOevI";
export var envelopeImage = "ProviderLocationsIndexContact__envelopeImage__oK3W7";
export var flex = "ProviderLocationsIndexContact__flex__GKPVv";
export var flexColumn = "ProviderLocationsIndexContact__flexColumn__H9Ooa";
export var gap1 = "ProviderLocationsIndexContact__gap1__zfpsO";
export var gap2 = "ProviderLocationsIndexContact__gap2__HGJkT";
export var gap3 = "ProviderLocationsIndexContact__gap3__RzyTN";
export var gap4 = "ProviderLocationsIndexContact__gap4__v7Bhf";
export var gap5 = "ProviderLocationsIndexContact__gap5___qTOM";
export var providerContactContainer = "ProviderLocationsIndexContact__providerContactContainer__h5OGA";
export var providerName = "ProviderLocationsIndexContact__providerName__oA_XJ";
export var row = "ProviderLocationsIndexContact__row__cvvkl";
export var salesManagerImage = "ProviderLocationsIndexContact__salesManagerImage__mH3MT";